<template>
  <DataTable
    class="p-datatable-sm"
    stripedRows
    showGridlines
    filterDisplay="menu"
    responsiveLayout="scroll"
    rowHover
    lazy
    :value="i18nList"
    >
    <template #header>
      <div class="flex justify-content-end">
        <div class="mb-0 mr-4">
          <div class="field grid center mb-0">
            <LanguageSelector v-model="language" v-bind:excludedLanguages="i18nKeys" compact />
          </div>
        </div>
        <div class="mb-0 mr-2">
          <div class="field grid center mb-0">
            <Button
            :label="$t('buttons.addTranslation')"
            icon="pi pi-plus"
            iconPos="right"
            :disabled="!language"
            @click="initI18nLanguage"
            />
          </div>
        </div>

      </div>
    </template>

    <template #empty>
      {{$t('resources.artist.translations.empty')}}
    </template>

    <template #loading>
      {{$t('resources.artist.translations.loading')}}
    </template>

    <Column style="min-width: 120px;">
      <template #header>
        <div class="flex justify-content-center w-full">
          <div>{{$t('common.language')}}</div>
        </div>
      </template>
      <template #body="slotProps">
        <div class="flex justify-content-start pl-4 align-items-center w-full">
          <!-- <LanguageFlagDisplay :language="languageById(slotProps.data.id)" /> -->
          <Flag :id="slotProps.data.id" />
          <span class="ml-3">{{languageById(slotProps.data.id)?.name}}</span>
        </div>
      </template>
    </Column>
    <Column>
      <template #header>
        <div class="flex justify-content-center w-full">
          <div>{{ $t('common.firstname') }}</div>
        </div>
      </template>
      <template #body="slotProps">
        <div class="flex justify-content-start w-full">
          <div class="w-full">
            <InputText class="w-full" :modelValue="i18n[slotProps.data.id].firstname" v-debounce:300ms="(val) => changeFirstname(slotProps.data.id, val)" :placeholder="$t('common.firstname')" />
          </div>
        </div>
      </template>
    </Column>
    <Column>
      <template #header>
        <div class="flex justify-content-center w-full">
          <div>{{ $t('common.lastname') }}</div>
        </div>
      </template>
      <template #body="slotProps">
        <div class="flex justify-content-start w-full">
          <div class="w-full">
            <InputText class="w-full" :modelValue="i18n[slotProps.data.id].name" v-debounce:300ms="(val) => changeLastname(slotProps.data.id, val)" :placeholder="$t('common.lastname')" />
          </div>
        </div>
      </template>
    </Column>
    <Column>
      <template #header>
        <div class="flex justify-content-center w-full">
          <div>{{ $t('common.actions') }}</div>
        </div>
      </template>
      <template #body="slotProps">
        <div class="flex justify-content-center w-full">
          <Button
            v-if="!isDefaultLanguage(slotProps.data.id)"
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-outlined"
            @click="confirmRemove(slotProps.data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { ref } from 'vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import { mapGetters } from 'vuex'
import Flag from '@/components/resource/Flag.vue'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'
import { getDirective as vueDebounce } from 'vue-debounce'

import { useFlowEditor } from '@/compositions'

export default {
  components: {
    DataTable, Column, InputText,
    LanguageSelector,
    Flag
  },
  directives: {
    debounce: vueDebounce(3)
  },
  props: { editorKey: String },
  setup (props) {
    const language = ref ('')
    return {
      language,
      ...useFlowEditor(props.editorKey)
    }
  },
  computed: {
    ...mapGetters('resource', ['languageById']),
    i18n: {
      get () { return this.fieldGet('i18n') },
      set (val) { this.fieldSet({field: 'i18n', value: val}) }
    },
    i18nKeys: {
      get () { return Object.keys(this.i18n || {}) }
    },
    i18nList: {
      get () {
        return this.i18nKeys.map((key) => ({id: key}))
      }
    }
  },
  methods: {
    isDefaultLanguage (language) {
      return this.fieldGet('defaultLanguage.id') === language
    },
    initI18nLanguage () {
      this.i18n[this.language] = {
        firstname: '',
        name: ''
      }

      this.fieldObjSet({field: 'i18n', key: this.language, value: this.i18n[this.language]})

      this.language = null

      window.scrollTo(0, document.body.scrollHeight)
    },
    changeFirstname (language, value) {
      this.fieldSet({field: `i18n.${language}.firstname`, value})
      if (this.isDefaultLanguage(language)) {
        this.fieldSet({field: 'firstname', value})
      }
    },
    changeLastname (language, value) {
      this.fieldSet({field: `i18n.${language}.name`, value})
      if (this.isDefaultLanguage(language)) {
        this.fieldSet({field: 'name', value})
      }
    },
    confirmRemove (language) {
      this.$confirm.require({
        message: this.$t('resources.artist.translations.remove.message'),
        header: this.$t('resources.artist.translations.remove.header', { language: this.languageById(language)?.name || 'Unknown' }),
        acceptLabel: this.$t('actions.yes'),
        rejectLabel: this.$t('actions.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.fieldObjDel({field: `i18n`, key: language})
        },
        reject: () => {}
      })
    },
  },
  watch: {
    'doc.name': function (newName) {
      const docId = this.doc.id
      let title = `${this.doc.firstname} ${newName}`
      this.$breadcrumb.setCurrent(title, [
        {label: 'Resources', disabled: true},
        {label: 'Artists', disabled: false, to: '/resources/artists'},
        {label: title, disabled: true}
      ], {caption: '', badge: docId === 'new' ? this.$t('common.draft') : ''})
    },
    'doc.firstname': function (newFirstName) {
      const docId = this.doc.id
      let title = `${newFirstName} ${this.doc.name}`
      this.$breadcrumb.setCurrent(title, [
        {label: 'Resources', disabled: true},
        {label: 'Artists', disabled: false, to: '/resources/artists'},
        {label: title, disabled: true}
      ], {caption: '', badge: docId === 'new' ? this.$t('common.draft') : ''})
    }
  }
}
</script>
